// BS needs to be imported before React and other imports:
// https://docs.bugsnag.com/platforms/browsers/react/#bundled
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

import { pick } from 'dpl/shared/utils';
import { set } from 'dpl/shared/utils/object';

// eslint-disable-next-line import/no-mutable-exports
let BugsnagErrorBoundary = ({ children }) => children;

// HACK: BS degrades performance signficantly on /explore pages. not sure for
// cause but could be related to the page's heavy mem consumption, or frequent
// pushes to history state.
const isExplorePage = window.location.pathname.startsWith('/explore');

export function isThirdPartyScript(report) {
  try {
    const { hostname } = new URL(report.stacktrace[0].file);
    return ![
      window.__DPL_HOST, // inline scripts
      window.__DPL_ASSET_HOST // scripts from our CDN
    ].includes(hostname);
  } catch (err) {
    return false;
  }
}

export function lowerSeverityIfNeeded(report) {
  if (report.severity !== 'error') {
    return;
  }

  if (window.__DPL_UNSUPPORTED_BROWSER) {
    report.severity = 'warning';
    report.metaData.severityReason = 'Unsupported browser';
    return;
  }

  if (window.__DPL_HOST !== window.location.hostname) {
    report.severity = 'warning';
    report.metaData.severityReason = 'Proxied request';
    return;
  }

  if (isThirdPartyScript(report)) {
    report.severity = 'warning';
    report.metaData.severityReason = 'Third-party script';
  }
}

if (CONFIG.bugsnagKey && !isExplorePage) {
  if (!window.bugsnagClient) {
    window.bugsnagClient = bugsnag({
      apiKey: CONFIG.bugsnagKey,
      appVersion: CONFIG.appVersion,
      beforeSend(report) {
        lowerSeverityIfNeeded(report);

        report.metaData = {
          ...report.metaData,
          debugSessionId: window.__DPL_DEBUG_SESSION_ID,
          network: {
            ...(report.metaData.network || {}),
            ...pick(window.navigator.connection || {}, [
              'downlink',
              'effectiveType'
            ])
          }
        };
      }
    });

    window.bugsnagClient.use(bugsnagReact, React);

    window.bugsnagClient.config.releaseStage = CONFIG.bugsnagReleaseStage;

    window.addEventListener('offline', () => {
      set(
        window,
        'bugsnagClient.metaData.network.wentOffline',
        new Date().toISOString()
      );
    });

    window.addEventListener('online', () => {
      set(
        window,
        'bugsnagClient.metaData.network.wentOnline',
        new Date().toISOString()
      );
    });

    if (window.__DPL_CURRENT_USER_ID) {
      // set initial value from server document response. this will be overriden
      // with more information by `withCurrentuser` if the user is logged in.
      window.bugsnagClient.user = {
        id: window.__DPL_CURRENT_USER_ID
      };
    }
  }

  if (window.__DPL_PAGE_INFO) {
    window.bugsnagClient.context = window.__DPL_PAGE_INFO.category;
  }

  BugsnagErrorBoundary = window.bugsnagClient.getPlugin('react');
}

export default BugsnagErrorBoundary;
